import React, { Fragment, useRef, useState } from 'react'
import { view } from 'react-easy-state'
import { IconContext } from 'react-icons'
import { IoIosClose, IoIosNotifications } from 'react-icons/io'
import {
  Button,
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip
} from 'reactstrap'
import { mapStore } from '/common/stores'
import { DataLocation, GeoLocation } from '/common/types'
import { useSavedLocationsState } from '/common/utils'
import '/components/LocationDropdown.css'
import NotificationModal from '/components/NotificationModal'
import { OptionalIdRuleGroup } from '/components/PredicateBuilder'

export interface SavedLocation extends DataLocation {
  notificationRules?: OptionalIdRuleGroup
  enabled?: boolean
}

function LocationSaveModal({
  open,
  setOpen,
  location,
  saveLocation,
  nameUnique
}: {
  open: boolean
  setOpen: (open: boolean) => void
  location: SavedLocation
  saveLocation: (name: string, location: GeoLocation) => void
  nameUnique: (name: string) => boolean
}) {
  const [name, setName] = useState(location.name.split(',')[0] || location.name)
  const unique = nameUnique(name)

  const changeName = (e: React.FormEvent<HTMLInputElement>) =>
    setName(e.currentTarget.value)
  const toggle = () => setOpen(!open)
  const save = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (unique) {
      saveLocation(name, location)
      toggle()
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle} autoFocus={false}>
      <ModalHeader toggle={toggle}>Save Location</ModalHeader>
      <ModalBody>
        <p>
          Enter a name for the saved location below. By default it will be the
          street address.
        </p>
        <Form onSubmit={save}>
          <FormGroup row>
            <Label for="nameField" sm={2}>
              Name
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="name"
                id="nameField"
                value={name}
                onChange={changeName}
                placeholder="Personal location name"
                autoFocus={true}
                invalid={!unique}
              />
              {!unique && (
                <FormFeedback>Location name must be unique</FormFeedback>
              )}
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <small className="text-muted text-center flex-grow-1">
          <em>
            Location: {location.name} ({location.latitude}º,{' '}
            {location.longitude}
            º)
          </em>
        </small>
        <Button color="primary" onClick={save} disabled={!unique}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

interface Props {
  toggleLocationCard: (
    location: SavedLocation | DataLocation | null,
    open?: boolean
  ) => void
}

function LocationDropdown({ toggleLocationCard }: Props) {
  const deleteButton = useRef<HTMLButtonElement>(null)
  const configureButton = useRef<HTMLButtonElement>(null)

  const [open, setOpen] = useState(false)
  const [saveOpen, setSaveOpen] = useState(false)
  const [notifyOpen, setNotifyOpen] = useState(false)
  const [notifyLocation, setNotifyLocation] = useState<SavedLocation | null>(
    null
  )
  const toggleNotifyOpen = () => setNotifyOpen(!notifyOpen)

  const [
    savedLocations,
    setSavedLocations
    // comment to force this to not reformat :)
  ] = useSavedLocationsState<SavedLocation[]>([])

  const toggleOpen = () => setOpen(!open)

  const saveLocation = (name: string, location: GeoLocation) => {
    setSavedLocations(saved => [
      ...saved,
      {
        name,
        latitude: location.latitude,
        longitude: location.longitude
      } as SavedLocation
    ])
  }

  const deleteLocation = (location: SavedLocation) => {
    setSavedLocations(saved => saved.filter(val => val.name !== location.name))
  }

  const updateLocation = (location: SavedLocation) => {
    setSavedLocations(saved =>
      saved.map(val =>
        val.name === location.name ? { ...val, ...location } : val
      )
    )
  }

  const selectLocation = (location: SavedLocation) => {
    toggleLocationCard(location, true)
  }

  const isSaved = savedLocations.some(location => {
    return (
      mapStore.location !== null &&
      location.latitude === mapStore.location.latitude &&
      location.longitude === mapStore.location.longitude
    )
  })

  const nameUnique = (name: string): boolean =>
    savedLocations.every(location => location.name !== name)

  return (
    <Fragment>
      {mapStore.location && (
        <LocationSaveModal
          open={saveOpen}
          setOpen={setSaveOpen}
          location={mapStore.location}
          saveLocation={saveLocation}
          nameUnique={nameUnique}
        />
      )}
      {notifyLocation && (
        <NotificationModal
          open={notifyOpen}
          setOpen={state => {
            setNotifyLocation(null)
            setNotifyOpen(state)
          }}
          location={notifyLocation}
          updateLocation={updateLocation}
        />
      )}
      <ButtonDropdown isOpen={open} toggle={toggleOpen}>
        <DropdownToggle caret>Saved Locations</DropdownToggle>
        <DropdownMenu>
          {savedLocations.length === 0 && (
            <DropdownItem disabled>No locations saved yet.</DropdownItem>
          )}
          {savedLocations.map((location: SavedLocation, idx, arr) => {
            return (
              <Fragment key={idx}>
                <DropdownItem
                  tag="div"
                  className="saved-location d-flex clearfix"
                >
                  <div
                    className="saved-location-name flex-grow-1 align-self-center mr-5"
                    onClick={() => selectLocation(location)}
                  >
                    {location.name}
                  </div>
                  <IconContext.Provider value={{ size: '1.5em' }}>
                    <button
                      className="btn btn-sm btn-secondary ml-1 px-1 py-0"
                      ref={configureButton}
                      onClick={() => {
                        setNotifyLocation(location)
                        toggleNotifyOpen()
                      }}
                    >
                      <IoIosNotifications />
                    </button>
                  </IconContext.Provider>
                  <UncontrolledTooltip
                    placement="auto"
                    // required due to incorrect typings
                    target={(configureButton as unknown) as HTMLElement}
                    fade={false}
                  >
                    Configure Notifications
                  </UncontrolledTooltip>
                  <IconContext.Provider value={{ size: '2em' }}>
                    <button
                      className="btn btn-sm btn-danger ml-1 p-0"
                      onClick={() => deleteLocation(location)}
                      ref={deleteButton}
                    >
                      <IoIosClose />
                    </button>
                  </IconContext.Provider>
                  <UncontrolledTooltip
                    placement="auto"
                    // required due to incorrect typings
                    target={(deleteButton as unknown) as HTMLElement}
                    fade={false}
                  >
                    Unsave Location
                  </UncontrolledTooltip>
                </DropdownItem>
                {idx !== arr.length - 1 && <hr className="my-1 mx-4 p-0" />}
              </Fragment>
            )
          })}
          {mapStore.location !== null && (
            <Fragment>
              <DropdownItem divider />
              <DropdownItem
                disabled={isSaved}
                onClick={() => setSaveOpen(true)}
              >
                {isSaved ? 'Location already saved' : 'Save this location...'}
              </DropdownItem>
            </Fragment>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </Fragment>
  )
}

export default view(LocationDropdown)
