import React, { Fragment } from 'react'
import { view } from 'react-easy-state'
import { useForecastApi } from '/common/api'
import { Forecast, ForecastLocation, GeoLocation } from '/common/types'
import {
  Accordion,
  AccordionHeader,
  AccordionItem
} from '/components/Accordion'
import ApiLoadingFragment from '/components/ApiLoadingFragment'
import ForecastInfo from '/components/ForecastInfo'

interface Props {
  location: GeoLocation
}

function renderForecasts(forecasts: Forecast[]) {
  return (
    forecasts
      // sort forecasts by date
      .sort((a, b) => a.date_of.localeCompare(b.date_of))
      .map((forecast, idx, arr) => (
        <div key={idx}>
          <ForecastInfo forecast={forecast} />
          {idx !== arr.length - 1 && <hr className="mx-5 my-3" />}
        </div>
      ))
  )
}

function Forecasts({ location }: Props) {
  const { data, loading, errors } = useForecastApi(location)
  const validResponse = !loading && !errors && data.length > 0
  const validForecast = validResponse && 'forecasts' in data[0]

  let forecasts: ForecastLocation[] = []
  if (validForecast) forecasts = data[0].forecasts

  const disclaimer = (
    <Fragment>
      <p className="text-muted">
        Nowcasta makes use of public data from the <a href="http://www.bom.gov.au/" target="_blank">BoM</a>.
      </p>
    </Fragment>
  );

  return (
    <Fragment>
      {validForecast ? (
        // show forecasts grouped by location if more than one present
        // TODO: add truncation
        forecasts.length > 1 ? (
          <Accordion>
            {forecasts
              .sort((a, b) => a.distance - b.distance)
              .map((forecastInfo, idx) => {
                // open first forecast by default
                const first = idx === 0

                const Title = () => (
                  <AccordionHeader
                    title={forecastInfo.location_name}
                    subtitle={`${forecastInfo.distance.toFixed(1)}km away`}
                  />
                )

                return (
                  <AccordionItem key={idx} title={<Title />} startOpen={first}>
                    {renderForecasts(forecastInfo.values)}
                  </AccordionItem>
                )
              })}
          </Accordion>
        ) : forecasts.length > 0 ? (
          // Only one location present so get first forecast
          renderForecasts(forecasts[0].values)
        ) : (
              <div>No forecasts currently available for this location.</div>
            )
      ) : (
          <ApiLoadingFragment loading={loading} errors={errors}>
            <p>
              An error occured while loading forecast data. Please try again
              later.
          </p>
          </ApiLoadingFragment>
        )}
      <hr className="mt-0" />
      {disclaimer}
    </Fragment>
  )
}

export default view(Forecasts)
