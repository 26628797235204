import { store } from 'react-easy-state'
import { DataLocation } from '/common/types'

export interface MapStore {
  map: null
  loaded: boolean
  location: DataLocation | null
  open: boolean
}

// Global store to keep reference to map
export const mapStore = store({
  map: null,
  loaded: false,
  location: null,
  open: false
} as MapStore)
