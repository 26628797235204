import React, { Fragment, useState } from 'react'
import './Accordion.css'

interface Props {
  title: any
  children: any
  startOpen?: boolean
  toggleIcon?: boolean
}

export function AccordionItem({
  title,
  children,
  startOpen = true,
  toggleIcon = true
}: Props) {
  const [open, setOpen] = useState(startOpen)
  const toggle = () => setOpen(!open)

  const openHint = open ? 'Click to close' : 'Click to open'
  const openClass = open ? 'accordion-open' : 'accordion-closed'
  return (
    <div className={`accordion-item ${openClass}`}>
      <h4
        title={openHint}
        className="accordion-header clearfix"
        onClick={toggle}
      >
        {title}
        {toggleIcon && (
          <span className={`accordion-toggle ${openClass}`} title={openHint}>
            {open ? '▼' : '▶'}
          </span>
        )}
      </h4>
      {open && (
        <div className={`accordion-item-content ${openClass}`}>{children}</div>
      )}
    </div>
  )
}

export function Accordion({ children }: { children: any }) {
  return <div className="accordion">{children}</div>
}

export function AccordionHeader({
  title,
  subtitle
}: {
  title: string
  subtitle: string | JSX.Element
}) {
  return (
    <Fragment>
      {title}
      <span
        className="ml-2 text-muted"
        style={{
          fontSize: '1rem'
        }}
      >
        {subtitle}
      </span>
    </Fragment>
  )
}
