import { memo } from 'react'
import { RuleGroup } from 'react-querybuilder'
import { queryNowcastApi, UPDATE_FREQUENCY } from '/common/api'
import { DataLocation, RadarCast } from '/common/types'
import { useInterval, useSavedLocationsState } from '/common/utils'
import {
  OptionalIdRuleGroup,
  parseRuleGroup
} from '/components/PredicateBuilder'

export interface SavedLocation extends DataLocation {
  notificationRules?: OptionalIdRuleGroup
  enabled?: boolean
}

function processNotification(location: SavedLocation) {
  // const { data, loading, errors } = useNowcastApi(location, new Date())
  const queryData = queryNowcastApi(location)

  queryData
    .then(data => {
      // all responses return an array of NowcastResponse, even if only asking for one location
      // so grab first element of NowcastResponse[] and use that.
      const validNowcast = data.length > 0 && 'radar_casts' in data[0]
      let radarCasts: RadarCast[] = []
      if (validNowcast) radarCasts = data[0].radar_casts

      // iterate over radar predictions by distance
      eachRadarLoop: for (const radarCast of radarCasts.sort(
        (a, b) => a.distance - b.distance
      )) {
        for (const cast of radarCast.values) {
          const castTime = new Date(cast.time)
          const now = new Date()

          // only notify for predictions, not old values
          if (castTime > now) {
            const time = castTime.toLocaleTimeString('en-AU', {
              hour12: false,
              hour: 'numeric',
              minute: 'numeric'
            })
            const time12hr = castTime.toLocaleTimeString('en-AU', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric'
            })
            const match = parseRuleGroup(
              location.notificationRules as RuleGroup,
              {
                intensity: cast.intensity,
                time,
                probability: cast.probability
              }
            )
            // we matched rules, don't notify for any others
            if (match.result === true) {
              // show notification
              if (Notification.permission === 'granted') {
                const notify = new Notification(
                  `Weather alert at "${location.name}" for ${time12hr}`,
                  {
                    body: match.asString.join(' '),
                    tag: 'nowcast',
                    renotify: false,
                    requireInteraction: true
                  }
                )
                notify.onclick = event => {
                  window.location.href = `/?loc=${location.longitude},${location.latitude}`
                }
                // close after 10 seconds
                setTimeout(notify.close.bind(notify), 10 * 1000)
              }
              break eachRadarLoop
            }
          }
        }
      }
    })
    .catch(err => console.log(err))
}

function NotificationProvider({}) {
  const [
    savedLocations,
    setSavedLocations
    // comment to force this to not reformat :)
  ] = useSavedLocationsState<SavedLocation[]>([])

  const processNotifications = () => {
    savedLocations
      .filter(
        location =>
          'notificationRules' in location &&
          'enabled' in location &&
          location.enabled === true
      )
      .forEach(location => processNotification(location))
  }

  processNotifications()
  useInterval(processNotifications, 1000 * UPDATE_FREQUENCY)

  return null
}

export default memo(NotificationProvider)
