import React, { Fragment, memo, useEffect, useState, useCallback } from 'react'
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner
} from 'reactstrap'
import probabilityScale from '/assets/probabilityscale.png'
import { RadarCast } from '/common/types'
import '/components/RadarViewer.css'
import { useForceUpdate } from '/common/utils'

const TimePill = memo(
  ({
    onClick,
    time,
    active
  }: {
    onClick: () => void
    time: string
    active: boolean
  }) => {
    const now = new Date()
    const parsedTime = new Date(time)

    const old = parsedTime < now

    return (
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          active={active}
          className={old && !active ? 'text-muted' : undefined}
        >
          {parsedTime.toLocaleTimeString('en-AU', {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric'
          })}
        </NavLink>
      </NavItem>
    )
  },
  (prevProps, nextProps) =>
    nextProps.active === prevProps.active && nextProps.time === prevProps.time
)

const TimeSelector = memo(
  ({
    radar,
    setImage,
    currentImage
  }: {
    radar: RadarCast
    setImage: (image: number) => void
    currentImage: number
  }) => {
    return (
      <Nav pills>
        {radar.values.map((val, idx) => {
          const setImg = () => setImage(idx)

          return (
            <TimePill
              key={idx}
              onClick={setImg}
              active={idx === currentImage}
              time={val.time}
            />
          )
        })}
      </Nav>
    )
  },
  (prevProps, nextProps) => nextProps.currentImage === prevProps.currentImage
)

const RadarImages = memo(
  ({
    radar,
    showChance,
    currentImage
  }: {
    radar: RadarCast
    showChance: boolean
    currentImage: number
  }) => {
    const code = radar.site_code.toString().padStart(2, '0');
    const is_us = !parseInt(code);
    var _url = "http://www.bom.gov.au/products/radar_transparencies/IDR" + code + "2.background.png";
    if (is_us) {
      _url = "https://radar.weather.gov/ridge/Overlays/Topo/Short/" + code + "_Topo_Short.jpg";
    }
    const defaultState = radar.values.reduce(
      (o, key, idx) => ({ ...o, [idx]: false }),
      {}
    ) as { [key: number]: boolean }
    const [loadingStates, setLoadingStates] = useState({ ...defaultState })

    const forceUpdate = useForceUpdate()

    useEffect(() => {
      setLoadingStates({ ...defaultState })
    }, [showChance])

    return (
      <Fragment>
        <img
          id="radar-background"
          className="radar-layer"
          src={_url}
        />
        {is_us ?
          <span>
            <img
              id="radar-topography"
              className="radar-layer"
              src={`https://radar.weather.gov/ridge/Overlays/County/Short/${code}_County_Short.gif`}
            />
            <img
              id="radar-topography"
              className="radar-layer"
              src={`https://radar.weather.gov/ridge/Overlays/Rivers/Short/${code}_Rivers_Short.gif`}
            />
            <img
              id="radar-topography"
              className="radar-layer"
              src={`https://radar.weather.gov/ridge/Overlays/Cities/Short/${code}_City_Short.gif`}
            />
            <img
              id="radar-topography"
              className="radar-layer"
              src={`https://radar.weather.gov/ridge/Overlays/Highways/Short/${code}_Highways_Short.gif`}
            /> 
          </span> :
          <img
            id="radar-topography"
            className="radar-layer"
            src={`http://www.bom.gov.au/products/radar_transparencies/IDR${code}2.topography.png`}
          />
        }
        {loadingStates[currentImage] === false && (
          <div
            id="radar-spinner"
            className="radar-layer d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ width: '5rem', height: '5rem' }}
              color="primary"
            />
          </div>
        )}
        {radar.values.map((val, idx) => {
          const src = showChance ? val.probability_img : val.intensity_img
          const url = `/api/v1${src}`

          const onLoad = () => {
            setLoadingStates(states => {
              states[idx] = true
              return states
            })
            forceUpdate()
          }

          return (
            src && (
              <img
                className={`radar-img radar-layer ${
                  currentImage !== idx
                    ? 'd-none'
                    : loadingStates[idx] === false
                    ? 'invisible'
                    : 'visible'
                }`}
                src={url}
                key={idx}
                onLoad={onLoad}
              />
            )
          )
        })}
        {!is_us ?
          <span><img
            id="radar-locations"
            className="radar-layer"
            src={`http://www.bom.gov.au/products/radar_transparencies/IDR${code}2.locations.png`}
          />
          <img
            id="radar-key"
            className="radar-layer"
            src={
              showChance
                ? probabilityScale
                : 'http://www.bom.gov.au/products/radar_transparencies/IDR.legend.0.png'
            }
          /></span> : null
        }
      </Fragment>
    )
  },
  (prevProps, nextProps) =>
    nextProps.currentImage === prevProps.currentImage &&
    nextProps.showChance === prevProps.showChance
)

const ViewerBody = memo(
  ({
    radar,
    showChance,
    paused
  }: {
    radar: RadarCast
    showChance: boolean
    paused: boolean
  }) => {
    const [image, setImage] = useState(0)
    const [timer, setTimer] = useState(undefined as number | undefined)

    useEffect(() => {
      if (paused) {
        clearInterval(timer)
      } else {
        const id = setInterval(() => {
          setImage(img => (img + 1) % radar.values.length)
        }, 500)
        setTimer(id)
      }

      return () => clearInterval(timer)
    }, [paused])

    return (
      <Fragment>
        <Row className="no-gutters">
          <Col className="mx-auto radar-img-wrapper">
            <RadarImages
              radar={radar}
              showChance={showChance}
              currentImage={image}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <TimeSelector
              radar={radar}
              setImage={setImage}
              currentImage={image}
            />
          </Col>
        </Row>
      </Fragment>
    )
  },
  (prevProps, nextProps) =>
    nextProps.showChance === prevProps.showChance &&
    nextProps.paused === prevProps.paused
)

function RadarViewer({
  radar,
  open,
  toggle
}: {
  radar: RadarCast
  open: boolean
  toggle: () => void
}) {
  const [isPause, setPause] = useState(true)
  const [showChance, setShowChance] = useState(false)

  const pauseToggle = useCallback(() => {
    setPause(true)
    toggle()
  }, [toggle])

  return (
    <Modal size="lg" isOpen={open} toggle={pauseToggle}>
      <ModalHeader toggle={toggle}>Radar Images</ModalHeader>
      <ModalBody>
        <ViewerBody radar={radar} showChance={showChance} paused={isPause} />
      </ModalBody>
      <ModalFooter>
        <Button className="mr-2" onClick={() => setPause(!isPause)}>
          {isPause ? 'Play Radar Loop' : 'Pause Radar Loop'}
        </Button>
        <CustomInput
          type="switch"
          id="exampleCustomSwitch"
          name="customSwitch"
          label="Show probability of rainfall above 0.2mm/h"
          checked={showChance}
          onChange={e => {
            setShowChance(e.currentTarget.checked)
          }}
        />
        <div className="text-muted text-center flex-grow-1" />
        <Button color="secondary" onClick={pauseToggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RadarViewer
