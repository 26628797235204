import React from 'react'
import { view } from 'react-easy-state'
import { ApiHook, useRadarApi } from '/common/api'
import { mapStore } from '/common/stores'
import { DataLocation, Radar } from '/common/types'
import { loadMapworks, createRadarPoint } from '/common/utils'
import MicrosoftLoader from '/components/MicrosoftLoader'
import '/components/Map.css'

function withRadarApi(Component: any) {
  return function WrappedComponent(props: any) {
    const resp = useRadarApi()
    return <Component {...props} radarApi={resp} />
  }
}

interface Props {
  radarApi: ApiHook<Radar[]>
  toggleLocationCard: (location?: DataLocation, open?: boolean) => void
}

class Map extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  public componentDidUpdate() {
    if (mapStore.loaded) this.renderMap()
  }

  public render() {
    return (
      <MicrosoftLoader>
        <div id="embed1"></div>
      </MicrosoftLoader>
    )
  }

  private renderMap = () => {
    const { radarApi, toggleLocationCard } = this.props
    if (!mapStore.map) {
      loadMapworks(() => this.renderMap())
    } else {
      if (!radarApi.errors && !radarApi.loading && radarApi.data) {
        createRadarPoint(radarApi.data);
        console.log("X")

        mapStore.map.on("feature:mouseclick", (event: any) => {
          if (event.getFeature() && (event.getFeature().getLayer().getTitle() === "Radar"
            || event.getFeature().getLayer().getTitle() === "US Radars")) {
            toggleLocationCard({
              "name": event.getFeature().getFields() && event.getFeature().getFields().label ? event.getFeature().getFields().label
                : (event.getFeature().getFields() && event.getFeature().getFields().Name ? event.getFeature().getFields().Name : null),
              "latitude": event.getFeature().getY(),
              "longitude": event.getFeature().getX()
            }, true);
          }
        });
      }

    }
  }
}

export default withRadarApi(view(Map))
