import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { view } from 'react-easy-state'
import { Alert, Col, Container, Row } from 'reactstrap'
import { QueryParamConfig, useQueryParam } from 'use-query-params'
import { mapStore } from '/common/stores'
import { DataLocation, GeoLocation } from '/common/types'
import {
  addPin,
  latLongCardinal,
  makeGeoLocation,
  useForceUpdate
} from '/common/utils'
import LocationCard from '/components/LocationCard'
import Navbar from '/components/Navbar'
import Map from '/components/Map'
import history from '/history'
import '/styles.css'
import Stats from '/components/Stats'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-154213061-1');
ReactGA.pageview('/homepage');

const LongLatParam: QueryParamConfig<
  GeoLocation | null,
  GeoLocation | undefined
  > = {
  encode(location) {
    if (location === null) return undefined
    return `${location.longitude},${location.latitude}`
  },

  decode(location) {
    if (!location.length) {
      return undefined
    }

    const locString = location instanceof Array ? location[0] : location
    if (!locString.length) {
      return undefined
    }

    const split = locString.split(',')
    if (split.length === 2) {
      const geoloc = makeGeoLocation({
        longitude: split[0],
        latitude: split[1]
      })
      if (geoloc !== null) return geoloc
    }

    // fallback for non-matching strings
    return undefined
  }
}

// Toggle the location card visibility
const toggleLocationCard = (
  location: DataLocation | null = null,
  open: boolean = !mapStore.open
) => {
  // setUserTriggered(false)
  mapStore.location = location || null
  mapStore.open = open
}

function App({ }) {
  // indicate that the panel is opening due to user interaction
  // this is done to differentiate from query string triggered opens
  const [fromPin, setFromPin] = useState(false)
  const [dLoc, setDataLoc] = useState(null)

  // allows loading a location by URL i.e. `?loc=longitude,latitude`
  // const [queryParams, setQueryParams] = useQueryParams()
  const [loc, setLoc] = useQueryParam('loc', LongLatParam)

  // remember last location to ensure multiple queries do not occur
  const [pastLocation, setPastLocation] = useState<GeoLocation | undefined>(undefined)

  const [error, setError] = useState<string | null>(null)

  // force updates on history change
  const forceUpdate = useForceUpdate()
  useEffect(() => {
    // listen for changes to the URL and force the app to re-render
    history.listen(() => {
      forceUpdate()
    })
  }, [])

  // Toggle location card indirectly by updating the query string
  const queryToggleLocationCard = useCallback(
    (location: DataLocation | null = null, open: boolean = !mapStore.open) => {
      // indicates a user triggered this open
      setFromPin(true)
      setDataLoc(location as DataLocation)
      setLoc(open ? (location as GeoLocation) : null, 'pushIn')
      mapStore.open = open
    },
    [location, open]
  )

  // handle user navigating back
  if (loc === undefined && pastLocation !== undefined) {
    toggleLocationCard(null, false)
  }

  // wait for map to have loaded
  if (
    mapStore.loaded &&
    loc !== undefined &&
    loc !== pastLocation) {
    // save location to avoid unneeded requests
    setPastLocation(loc)

    if (fromPin) {
      toggleLocationCard(
        {
          name: `Location at ${dLoc.name}`,
          latitude: loc.latitude,
          longitude: loc.longitude
        }, true);
    } else {
      addPin(dLoc);
      toggleLocationCard(
        {
          name: `Location at ${latLongCardinal(loc)}`,
          latitude: loc.latitude,
          longitude: loc.longitude
        }, true)
    }
  }

  return (
    <Fragment>
      <Navbar
        toggleLocationCard={queryToggleLocationCard}
        mapOpen={!mapStore.open}
      />
      {mapStore.open && (
        <Container className="content my-4 pb-5">
          <Row>
            <Col lg={12}>
              {error !== null && <Alert color="danger">{error}</Alert>}
              <LocationCard
                location={mapStore.location}
                toggleLocationCard={queryToggleLocationCard}
              />
            </Col>
          </Row>
        </Container>
      )}
      <Col className={`${mapStore.open ? 'd-none' : ''} p-0`}>
        {!mapStore.open && (
          <Stats toggleLocationCard={queryToggleLocationCard} />
        )}
        <Map toggleLocationCard={queryToggleLocationCard} />
      </Col>
    </Fragment>
  )
}

export default view(App)
