import React, { Fragment, useState } from 'react'
import { view } from 'react-easy-state'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem
} from 'reactstrap'
import { mapStore } from '/common/stores'
import { DataLocation } from '/common/types'
import { BackToMapButton, ResetMapButton } from '/components/Buttons'
import LocationDropdown from '/components/LocationDropdown'
import LocationSearchInput from '/components/LocationSearchInput'

import './Navbar.css'

interface Props {
  toggleLocationCard: (location: DataLocation | null, open?: boolean) => void
  mapOpen: boolean
}

function NavigationBar({ toggleLocationCard, mapOpen = false }: Props) {
  const [collapsed, setCollapsed] = useState(false)
  const toggle = () => setCollapsed(!collapsed)

  return (
    <Navbar color="dark" dark expand="lg">
      <Container>
        <NavbarBrand href="/">nowcasta</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={collapsed} navbar>
          {mapStore.loaded && (
            <Fragment>
              <Nav className="ml-auto flex-fill justify-content-end" navbar>
                <NavItem className="mr-md-2 my-2 my-lg-0">
                  <LocationDropdown toggleLocationCard={toggleLocationCard} />
                </NavItem>
                <NavItem className="mr-md-2 my-2 my-lg-0 location-search">
                  <LocationSearchInput toggleLocationCard={toggleLocationCard} />
                </NavItem>
                {mapOpen ? (
                  <NavItem className="my-2 my-lg-0">
                    <ResetMapButton />
                  </NavItem>
                ) : (
                    <NavItem className="mr-md-2 my-2 my-lg-0">
                      <BackToMapButton onClick={() => toggleLocationCard(null, false)} />
                    </NavItem>
                  )}
              </Nav>
            </Fragment>
          )}
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default view(NavigationBar)
