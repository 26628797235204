import React, { Fragment, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { view } from 'react-easy-state'
import { Button, Col, Form } from 'reactstrap'
import { UPDATE_FREQUENCY, useObservationApi } from '/common/api'
import { GeoLocation, Observation, ObservationLocation } from '/common/types'
import { useInterval } from '/common/utils'
import {
  Accordion,
  AccordionHeader,
  AccordionItem
} from '/components/Accordion'
import ApiLoadingFragment from '/components/ApiLoadingFragment'
import LastUpdated from '/components/LastUpdated'
import ObservationInfo from '/components/ObservationInfo'

interface Props {
  location: GeoLocation
}

function renderObservations(observations: Observation[]) {
  return (
    observations
      // sort observations by date
      .sort((a, b) => a.time.localeCompare(b.time))
      .reverse()
      .slice(0, 3)
      .map((observation, idx, arr) => (
        <div key={idx}>
          <ObservationInfo observation={observation} />
          {idx !== arr.length - 1 && <hr className="mx-5 my-3" />}
        </div>
      ))
  )
}

function Observations({ location }: Props) {
  const defDate = new Date()

  // start range at 1 days before today
  const startDefDate = new Date(defDate)
  startDefDate.setDate(startDefDate.getDate() - 1)

  const [startDate, setStartDate] = useState(startDefDate)
  const [endDate, setEndDate] = useState(defDate)
  const [time, setTime] = useState(null as Date | null)

  const [lastUpdate, setLastUpdate] = useState(new Date())

  const { data, loading, errors } = useObservationApi(
    location,
    startDate.toISOString(),
    endDate.toISOString(),
    time !== null
      ? time.toLocaleTimeString('en-AU', {
          hour12: false,
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'UTC'
        })
      : undefined,
    lastUpdate
  )

  const validResponse = !loading && !errors && data.length > 0
  const validObservations = validResponse && 'observations' in data[0]

  let observations: ObservationLocation[] = []
  if (validObservations) observations = data[0].observations

  const reset = (e: React.SyntheticEvent<any>) => {
    e.preventDefault()
    setStartDate(startDefDate)
    setEndDate(defDate)
    setTime(null)
  }

  // update UI periodically
  useInterval(() => {
    setLastUpdate(new Date())
  }, 1000 * UPDATE_FREQUENCY)

  return (
    <Fragment>
      <LastUpdated date={lastUpdate} message="Last refreshed" />
      <hr />
      <div className="mb-2">Select date range:</div>
      <Form inline onSubmit={reset}>
        <div className="form-row">
          <Col xs={4}>
            <DatePicker
              className="form-control form-control-sm w-100"
              selected={startDate}
              onChange={date => {
                setStartDate(date!)
                setLastUpdate(new Date())
              }}
              dateFormat="d MMMM, yyyy"
              todayButton="Today"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                  padding: 5
                }
              }}
            />
          </Col>
          <Col className="p-0" xs={1}>
            <span> — </span>
          </Col>
          <Col xs={4}>
            <DatePicker
              className="form-control form-control-sm w-100"
              selected={endDate}
              onChange={date => {
                setEndDate(date!)
                setLastUpdate(new Date())
              }}
              dateFormat="d MMMM, yyyy"
              todayButton="Today"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                  padding: 5
                }
              }}
            />
          </Col>
          <Col xs={3}>
            <DatePicker
              className="form-control form-control-sm w-100"
              selected={time}
              onChange={date => {
                setTime(date)
                setLastUpdate(new Date())
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
              isClearable
              placeholderText="Select time"
            />
          </Col>
          <Col xs={12} className="mt-2">
            <Button size="sm" onClick={reset}>
              Reset
            </Button>
          </Col>
        </div>
      </Form>
      <hr />
      {validObservations ? (
        <Fragment>
          {// show observations grouped by location if more than one present
          observations.length > 1 ? (
            <Accordion>
              {observations
                .sort((a, b) => a.distance - b.distance)
                .map((loc, idx) => {
                  const Title = () => (
                    <AccordionHeader
                      title={loc.station_name}
                      subtitle={`${loc.distance.toFixed(1)}km away`}
                    />
                  )

                  // open first observations by default
                  const first = idx === 0

                  return (
                    <AccordionItem
                      key={idx}
                      title={<Title />}
                      startOpen={first}
                    >
                      {renderObservations(loc.values)}
                    </AccordionItem>
                  )
                })}
            </Accordion>
          ) : observations.length > 0 ? (
            // Only one location present so get first observations
            renderObservations(observations[0].values)
          ) : (
            <div>
              No observations currently available for this location, given the
              above filter options. Perhaps try a different time or date range?
            </div>
          )}
        </Fragment>
      ) : (
        <ApiLoadingFragment loading={loading} errors={errors}>
          <p>
            An error occured while loading observation data. Please try again
            later.
          </p>
        </ApiLoadingFragment>
      )}
    </Fragment>
  )
}

export default view(Observations)
