import React, { Fragment } from 'react'
import { view } from 'react-easy-state'
import LoadingElement from '/components/LoadingElement'

interface Props {
  loading: boolean
  errors: any
}

const ApiLoadingFragment: React.FC<Props> = ({ loading, errors, children }) => {
  return (
    <Fragment>
      {loading && <LoadingElement />}
      {errors && children}
    </Fragment>
  )
}

export default view(ApiLoadingFragment)
