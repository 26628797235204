import React, { Fragment, useState } from 'react'
import { RuleGroup } from 'react-querybuilder'
import {
  Button,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { DataLocation } from '/common/types'
import { grantNotificationPermission } from '/common/utils'
import {
  OptionalIdRuleGroup,
  PredicateBuilder
} from '/components/PredicateBuilder'

export interface SavedLocation extends DataLocation {
  notificationRules?: OptionalIdRuleGroup
  enabled?: boolean
}

function NotificationModalBody({
  location,
  updateLocation,
  toggle
}: {
  location: SavedLocation
  updateLocation: (location: SavedLocation) => void
  toggle: () => void
}) {
  const query: OptionalIdRuleGroup = {
    rules: [
      {
        field: 'intensity',
        value: 10,
        operator: '>'
      }
    ],
    combinator: 'AND'
  }

  const [rules, setRules] = useState(
    'notificationRules' in location ? location.notificationRules : query
  )
  const [enabled, setEnabled] = useState(
    'enabled' in location ? location.enabled : true
  )

  const updateNotificationSettings = () =>
    updateLocation({
      ...location,
      enabled,
      notificationRules: rules
    })

  const save = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    grantNotificationPermission()
    updateNotificationSettings()
    toggle()
  }

  return (
    <Fragment>
      <ModalBody>
        <p>
          Configure options for push notifications below.
          <br />
          These notifications will only trigger while the Nowcasta website is
          open.
        </p>

        <div className="mb-3">
          <CustomInput
            type="switch"
            id="exampleCustomSwitch"
            name="customSwitch"
            label="Enable notifications for this location"
            checked={enabled}
            onChange={e => {
              setEnabled(e.currentTarget.checked) // updateNotificationSettings()
            }}
          />
        </div>

        <fieldset disabled={!enabled}>
          <PredicateBuilder
            onQueryChange={q => {
              setRules(q) // updateNotificationSettings()
            }}
            query={rules as RuleGroup | undefined}
          />
        </fieldset>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <div className="flex-grow-1" />
        <Button color="primary" onClick={save}>
          Save
        </Button>
      </ModalFooter>
    </Fragment>
  )
}

/**
 * Modal to configure smart notification rules for a location
 */
function NotificationModal({
  open,
  setOpen,
  location,
  updateLocation
}: {
  open: boolean
  setOpen: (open: boolean) => void
  location: SavedLocation
  updateLocation: (location: SavedLocation) => void
}) {
  const toggle = () => setOpen(!open)

  return (
    <Modal size="lg" isOpen={open} toggle={toggle} autoFocus={false}>
      <ModalHeader toggle={toggle}>
        Configure Notifications for "{location.name}"
      </ModalHeader>
      <NotificationModalBody
        location={location}
        updateLocation={updateLocation}
        toggle={toggle}
      />
    </Modal>
  )
}

/**
 * Modal to configure smart notification rules for a location.
 */
export default NotificationModal
