import React from 'react'
import { view } from 'react-easy-state'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle
} from 'reactstrap'
import { DataLocation } from '/common/types'
import Forecasts from '/components/Forecasts'
import '/components/LocationCard.css'
import Nowcasts from '/components/Nowcasts'
import Observations from '/components/Observations'

interface Props {
  toggleLocationCard: (location: DataLocation | null, open?: boolean) => void
  location: DataLocation | null
}

function LocationCard({ toggleLocationCard, location }: Props) {
  const hideCard = () => toggleLocationCard(location, false)
  return (
    <Card className="home-card">
      <CardHeader>
        <h1 className="text-center">
          {location !== null ? location.name : 'Loading...'}
        </h1>
      </CardHeader>
      <div className="col-12">
        <Card>
          <CardBody className="text-center">
            <CardTitle tag="h2">
              Nowcasts <hr className="mb-0" />
            </CardTitle>
            {location !== null && <Nowcasts location={location} />}
          </CardBody>
        </Card>
      </div>
      <div className="card-columns location-cards-wrapper">
        <Card>
          <CardBody className="text-center">
            <CardTitle tag="h2">
              Forecasts <hr className="mb-0" />
            </CardTitle>
            {location !== null && <Forecasts location={location} />}
          </CardBody>
        </Card>
        <Card>
          <CardBody className="text-center">
            <CardTitle tag="h2">
              Observations <hr className="mb-0" />
            </CardTitle>
            {location !== null && <Observations location={location} />}
          </CardBody>
        </Card>
      </div>
      <CardFooter>
        <Button block onClick={hideCard}>
          Close
        </Button>
      </CardFooter>
    </Card>
  )
}

export default view(LocationCard)
