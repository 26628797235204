import React from 'react'
import { view } from 'react-easy-state'
import { Col, Row } from 'reactstrap'
import 'weathericons/css/weather-icons-wind.css'
import 'weathericons/css/weather-icons.css'
import { Observation } from '/common/types'

interface Props {
  observation: Observation
}

function ObservationInfo({ observation }: Props) {
  const start = new Date(observation.time)

  const fullData = [
    observation.actual_temp,
    observation.apparent_temp,
    observation.cumulative_rainfall,
    observation.humidity,
    observation.wind_direction,
    observation.wind_speed
  ].every(val => val !== null)

  return (
    <div className="text-center mt-1">
      <Row className="align-items-center">
        <Col xs={12} className="mb-3">
          <span className="h5">
            {start.toLocaleDateString('en-AU', {
              minute: 'numeric',
              hour: 'numeric',
              weekday: 'short',
              day: 'numeric'
            })}
          </span>
          {// Warn user limited data available for this timepoint
          !fullData && (
            <div className="text-muted mt-1">
              <small>Limited data available</small>
            </div>
          )}
        </Col>
        {observation.actual_temp !== null && (
          <Col
            sm={6}
            md
            lg
            xl={6}
            className="text-center mb-3 mb-sm-0 mb-md-0 mx-auto"
            style={{ whiteSpace: 'nowrap' }}
          >
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                Temp
                <span className="text-danger h5 my-0 ml-3">
                  {observation.actual_temp}
                </span>
                <span className="text-danger ml-1">°C</span>
              </Col>
              {/* Force next columns to break to new line */}
              <div className="w-100" />
              {observation.apparent_temp && (
                <Col>
                  <small className="text-muted">
                    Feels like {observation.apparent_temp}°C
                  </small>
                </Col>
              )}
            </Row>
          </Col>
        )}
        {observation.wind_direction !== null &&
          observation.wind_speed !== null && (
            <Col
              sm={6}
              md
              lg
              xl={6}
              className="text-center mb-sm-3 mb-0 mb-md-0 mx-auto"
              style={{ whiteSpace: 'nowrap' }}
            >
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  Wind
                  {observation.wind_direction !== 'CALM' && (
                    <i
                      className={`text-primary ml-2 my-n2 h2 wi wi-wind wi-from-${observation.wind_direction.toLowerCase()}`}
                    />
                  )}
                  <span className="text-primary h5 my-0 ml-2">
                    {observation.wind_speed}
                  </span>
                  <span className="text-primary ml-1">km/h</span>
                </Col>
                {/* Force next columns to break to new line */}
                <div className="w-100" />
                <Col>
                  <small className="text-muted">
                    {observation.wind_direction !== 'CALM'
                      ? `Coming from ${observation.wind_direction}`
                      : 'No wind measured'}
                  </small>
                </Col>
              </Row>
            </Col>
          )}
        {/* Force next columns to break to new line */}
        {/* <div className="w-100"></div> */}
        {observation.humidity !== null && (
          <Col
            className="text-center mt-3 mt-sm-0 mt-lg-3 mx-auto"
            style={{ whiteSpace: 'nowrap' }}
          >
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                Humidity
                <span className="text-primary h5 my-0 ml-3">
                  {observation.humidity}
                </span>
                <span className="text-primary ml-1">%</span>
              </Col>
            </Row>
          </Col>
        )}
        {observation.cumulative_rainfall !== null && (
          <Col
            className="text-center mt-3 mt-sm-0 mt-lg-3 mx-auto"
            style={{ whiteSpace: 'nowrap' }}
          >
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                Rainfall
                <span className="text-primary h5 my-0 ml-3">
                  {observation.cumulative_rainfall}
                </span>
                <span className="text-primary ml-1">mm</span>
              </Col>
              {/* Force next columns to break to new line */}
              <div className="w-100" />
              <Col>
                <small className="text-muted">Since 9am</small>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default view(ObservationInfo)
