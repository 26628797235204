import React, { Fragment, useState } from 'react'
import { view } from 'react-easy-state'
import { Button, Col, Row, Table } from 'reactstrap'
import { Prediction } from '/common/types'

interface Props {
  predictions: Prediction[]
  truncateCount?: number
}

function NowcastTable({ predictions, truncateCount = 5 }: Props) {
  const now = new Date()

  // truncate table if more than 5 entries
  const [expanded, setExpanded] = useState(
    predictions.length > truncateCount ? false : true
  )

  // does table has enough entries to be truncatable?
  const truncatable = predictions.length > truncateCount

  return (
    <Fragment>
      <Table striped className="text-center mb-0">
        <thead>
          <tr>
            <th>Time</th>
            <th className="prediction">Rainfall Prediction</th>
            <th>Probability ≥ 0.2mm/h</th>
          </tr>
        </thead>
        <tbody>
          {[...predictions]
            // cut array down to <truncateCount> elements if too long
            .slice(0, expanded ? predictions.length : truncateCount)
            .map((prediction, idx) => {
              const date = new Date(prediction.time)
              const old = date < now

              return (
                <Fragment key={idx}>
                  <tr className={old ? 'text-muted' : ''}>
                    <th scope="row" className={old ? 'font-weight-normal' : ''}>
                      {date.toLocaleTimeString('en-AU', {
                        hour12: true,
                        hour: 'numeric',
                        minute: 'numeric'
                      })}
                    </th>
                    <td className="prediction">
                      {prediction.intensity === 0
                        ? 'No rain'
                        : prediction.intensity.toFixed(2) + ' mm/hr'}
                    </td>
                    <td>
                      {prediction.probability === -1
                        ? 'N/A'
                        : (prediction.probability * 100).toFixed() + '%'}
                    </td>
                  </tr>
                </Fragment>
              )
            })}
        </tbody>
      </Table>
      {truncatable && (
        <Fragment>
          <Row className="my-3 text-muted">
            <Col>
              Showing {expanded ? predictions.length : truncateCount}/
              {predictions.length} nowcasts
            </Col>
          </Row>

          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Show Less' : 'Show More'}
          </Button>
        </Fragment>
      )}
    </Fragment>
  )
}

export default view(NowcastTable)
