import { useEffect, useState } from 'react'
import {
  ForecastResponse,
  ForecastLocation,
  Forecast,
  GeoLocation,
  NowcastResponse,
  ObservationResponse,
  ObservationLocation,
  Observation,
  Radar,
  Stats,
  Token
} from './types'

import $ from 'jquery'

const API_BASE = '/api/v1'
const APP = 'NOWCASTA';
var token = "";

export interface ApiHook<T> {
  data: T
  loading: boolean
  errors: boolean | any
}

// Generic API get function to handle loading and error states
export function useApi<T>(url: string, params: any[] = []): ApiHook<T> {
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState(null)
  const [data, setData] = useState({} as T)

  async function auth() {
    if (getCookie("token") === "") {
      const resp_ = await fetch(`${API_BASE}/gettoken`, {
        headers: { app: APP },
      });
      if (resp_.status == 200) {
        token = getCookie("token");
        fetchData();
      }
    } else {
      token = getCookie("token");
      fetchData();
    }
  };

  function getCookie(cname: string) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  async function fetchData() {
    const resp = await fetch(url, {
      headers: { authorization: token }
    })
    resp
      .json()
      .then((res: T) => {
        if (res[0] && ((res[0].forecasts && res[0].forecasts.length == 0) || (res[0].observations && res[0].observations.length == 0))) {
          const location = params[0] as GeoLocation;
          if (res[0].forecasts) {
            let fRes: T = [];
            $.ajax({
              url: "https://api.weather.gov/points/" + location.latitude + "%2C" + location.longitude,
              dataType: 'json', // type of file (text, json, xml, etc)
              type: "GET",
              async: false,
              crossDomain: true,
              success: function (data: any) {
                let _FormattedResponse: any = {
                  location: {
                    longitude: location.longitude,
                    latitude: location.latitude
                  },
                  forecasts: <ForecastLocation[]>[]
                };

                let _ForecastLocation: ForecastLocation = {
                  location_name: data.properties.radarStation,
                  distance: 0,
                  aac: "",
                  values: []
                };
                if (data.properties && data.properties.forecast) {
                  $.ajax({
                    url: data.properties.forecast + "?units=si",
                    dataType: 'json', // type of file (text, json, xml, etc)
                    type: "GET",
                    async: false,
                    crossDomain: true,
                    success: function (data_: any) { // callback for successful completion,
                      if (data_ && data_.properties && data_.properties.periods) {
                        data_.properties.periods.forEach((x: any) => {
                          var pop = 0;
                          if (x.detailedForecast) {
                            pop = x.detailedForecast.substring(x.detailedForecast.indexOf('%') - 3, x.detailedForecast.indexOf('%')).trim();
                          }
                          let _Forecast: Forecast = {
                            name: x.name,
                            date_of: x.startTime.substr(0, 10),
                            forecast_minimum: null,
                            forecast_maximum: x.temperature,
                            icon: x.icon,
                            precis: x.shortForecast,
                            precipitation_probability: (pop ? pop : "0") + "%"
                          };
                          _ForecastLocation.values.push(_Forecast);
                        });
                        _FormattedResponse.forecasts.push(_ForecastLocation);
                        fRes.push(_FormattedResponse);
                        setData(fRes);
                        setLoading(false)
                        setErrors(null)
                      }
                    },
                    error: function (res: any) {
                      setErrors(res)
                    }
                  });
                }
              },
              error: function (res: any) {
                setErrors(res)
              }
            });
          }

          if (res[0].observations) {
            let oRes: T = [];
            const from = encodeURIComponent(params[1].slice(0, 10) + "T00:00:00-00:00")
            const to = encodeURIComponent(params[2].slice(0, 10) + "T" + (params[3] ? params[3] : "23:59") + ":00-00:00")
            $.ajax({
              url: "https://api.weather.gov/points/" + location.latitude + "%2C" + location.longitude,
              dataType: 'json',
              type: "GET",
              async: false,
              crossDomain: true,
              success: function (data: any) {
                let _FormattedResponse: any = {
                  location: {
                    longitude: location.longitude,
                    latitude: location.latitude
                  },
                  observations: <ObservationLocation[]>[]
                };
                if (data.properties && data.properties.forecastZone) {
                  $.ajax({
                    url: data.properties.forecastZone,
                    dataType: 'json',
                    type: "GET",
                    async: false,
                    crossDomain: true,
                    success: function (data_: any) {
                      if (data_ && data_.properties && data_.properties.observationStations) {
                        data_.properties.observationStations.forEach((fz: any) => {
                          $.ajax({
                            url: fz + `/observations?start=${from}&end=${to}`,
                            dataType: 'json',
                            type: "GET",
                            async: false,
                            crossDomain: true,
                            success: function (data__: any) {
                              if (data__ && data__.features && data__.features[0]) {
                                let _ObservationLocation: ObservationLocation = {
                                  station_name: data__.features[0].properties.station.slice(-4),
                                  distance: getDistanceFromLatLonInKm(
                                    location.latitude,
                                    location.longitude,
                                    data__.features[0].geometry.coordinates[1],
                                    data__.features[0].geometry.coordinates[0]),
                                  aac: "",
                                  values: []
                                };

                                if (data__.features[0] && data__.features[0].properties.station) {
                                  $.ajax({
                                    url: data__.features[0].properties.station,
                                    dataType: 'json',
                                    type: "GET",
                                    async: false,
                                    crossDomain: true,
                                    success: function (data___: any) {
                                      if (data___ && data___.properties) {
                                        _ObservationLocation.station_name = data___.properties.name + " (" + data___.properties.stationIdentifier + ")";
                                      }
                                    }
                                  });
                                }

                                data__.features.forEach((obs: any) => {
                                  var wd = ""
                                  var x = obs.properties.windDirection.value;
                                  if (x > 270) { wd = "NW" } else if (x > 180) { wd = "SW" } else if (x > 90) { wd = "SE" } else { wd = "NE" }

                                  let _Observation: Observation = {
                                    time: new Date(obs.properties.timestamp).toISOString(),
                                    actual_temp: obs.properties.temperature && obs.properties.temperature.value != null ? obs.properties.temperature.value.toFixed() : null,
                                    apparent_temp: obs.properties.temperature && obs.properties.temperature.value != null ? obs.properties.temperature.value.toFixed() : null,
                                    wind_direction: wd,
                                    wind_speed: obs.properties.windSpeed.value,
                                    humidity: obs.properties.relativeHumidity && obs.properties.relativeHumidity.value != null ? obs.properties.relativeHumidity.value.toFixed() : null,
                                    cumulative_rainfall: obs.properties.precipitationLast6Hours && obs.properties.precipitationLast6Hours.value ? obs.properties.precipitationLast6Hours.value : 0
                                  };
                                  _ObservationLocation.values.push(_Observation);
                                });
                                _FormattedResponse.observations.push(_ObservationLocation);
                              }
                            }
                          });
                        });
                        oRes.push(_FormattedResponse);
                        setData(oRes);
                        setLoading(false)
                        setErrors(null)
                      }
                    },
                    error: function (res: any) {
                      setErrors(res)
                    }
                  });
                }
              },
              error: function (res: any) {
                setErrors(res)
              }
            });
          }
        } else {
          setData(res)
          setLoading(false)
        }
      })
      .catch(err => {
        setErrors(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    // tslint:disable-next-line: no-floating-promises
    auth()
  }, params)

  return { data, loading, errors }
}

// Get nowcast data
export function useNowcastApi(
  location: GeoLocation,
  refresh?: any
): ApiHook<NowcastResponse[]> {
  const url = `${API_BASE}/nowcast?loc=${location.longitude},${location.latitude}`
  return useApi<NowcastResponse[]>(url, [location, refresh])
}

export async function queryNowcastApi(
  location: GeoLocation
): Promise<NowcastResponse[]> {
  const url = `${API_BASE}/nowcast?loc=${location.longitude},${location.latitude}`
  const resp = await fetch(url)
  return resp.json()
}

// Get forecast data
export function useForecastApi(
  location: GeoLocation,
  refresh?: any
): ApiHook<ForecastResponse[]> {
  const url = `${API_BASE}/forecasts?loc=${location.longitude},${location.latitude}`
  return useApi<ForecastResponse[]>(url, [location, refresh])
}

// Get observation data
export function useObservationApi(
  location: GeoLocation,
  from?: string,
  to?: string,
  at?: string,
  refresh?: any
): ApiHook<ObservationResponse[]> {
  let url = `${API_BASE}/observations?loc=${location.longitude},${location.latitude}`
  if (from !== undefined) {
    url += `&from=${from}`
  }
  if (to !== undefined) {
    url += `&to=${to}`
  }
  if (at !== undefined) {
    url += `&at=${at}`
  }
  return useApi<ObservationResponse[]>(url, [location, from, to, at, refresh])
}

function getDistanceFromLatLonInKm(lat1: any, lon1: any, lat2: any, lon2: any) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: any) {
  return deg * (Math.PI / 180)
}

// Get major locations to show on map
export function useRadarApi(): ApiHook<Radar[]> {
  const url = `${API_BASE}/location`
  return useApi<Radar[]>(url)
}

// Get system statistics
export function useStatsApi(refresh?: any): ApiHook<Stats> {
  const url = `${API_BASE}/stats`
  return useApi<Stats>(url, [refresh])
}

// Get authentication token
export function getTokenApi(refresh?: any): ApiHook<Token> {
  const url = `${API_BASE}/gettoken`
  return useApi<Token>(url, [refresh])
}

/**
 * Update frequency in seconds.
 * (every 5 minutes)
 */
export const UPDATE_FREQUENCY = 5 * 60
