import React from 'react'
import { view } from 'react-easy-state'
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip
} from 'reactstrap'
import { mapStore } from '/common/stores'
import { DataLocation } from '/common/types'
import { addPin, latLongCardinal, reverseGeocode, geoCode } from '/common/utils'
import '/components/LocationSearchInput.css'

import $ from 'jquery'

interface State {
  locationPin: Microsoft.Maps.Pushpin | null
  locationPoly: Microsoft.Maps.Polygon | null
}

interface Props {
  toggleLocationCard: (location: DataLocation | null, open?: boolean) => void
}

class LocationSearchInput extends React.Component<Props, State> {
  public readonly state = {
    locationPin: null,
    locationPoly: null
  }

  private searchRef: any = null
  private tooltipRef: any = null
  private autosuggestManager: Microsoft.Maps.AutosuggestManager | null = null

  constructor(props: any) {
    super(props)

    this.searchRef = React.createRef()
    this.tooltipRef = React.createRef()
  }

  public render() {
    return (
      <div id="searchbox-container" style={{ flex: 1 }}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <Button
              innerRef={this.tooltipRef}
              // Disable locate button when page not secure
              disabled={!window.isSecureContext}
              onClick={this.userLocation}
            >
              Locate me
            </Button>
          </InputGroupAddon>
          <Input
            id="searchbox"
            innerRef={this.searchRef}
            placeholder="Enter an address or location..."
          />
          <InputGroupAddon addonType="append">
            <Button onClick={this.searchLocation}>Search</Button>
          </InputGroupAddon>
        </InputGroup>
        {// Notify user they can't geolocate due to non-secure page
          !window.isSecureContext && (
            <UncontrolledTooltip placement="bottom" target={this.tooltipRef}>
              Unavailable due to page not being secure (HTTPS)
          </UncontrolledTooltip>
          )}
      </div>
    )
  }

  public componentDidMount() {
    if (mapStore.loaded) this.initAutoSuggest()
  }

  // Get the current location of the user
  private userLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const loc = {
          name: "",
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }
        const name = reverseGeocode(loc);
        loc.name = name ? name : `${latLongCardinal(loc)}`;
        addPin(loc);
        this.props.toggleLocationCard(loc, true);
      },
      failure => {
        alert(failure.message)
        console.log(window.isSecureContext)
      }
    )
  }

  private initAutoSuggest = () => {
    const options: Microsoft.Maps.IAutosuggestOptions = {
      maxResults: 4,
      autoDetectLocation: true
      //countryCode: 'AU',
      //useMapView: true
    }

    this.autosuggestManager = new Microsoft.Maps.AutosuggestManager(options)

    // autosuggest
    this.autosuggestManager.attachAutosuggest(
      '#searchbox',
      '#searchbox-container',
      result => {
        const loc = {
          name: result.title,
          latitude: result.location.latitude,
          longitude: result.location.longitude
        };
        loc.name = loc.name ? loc.name : `${latLongCardinal(loc)}`;
        addPin(loc);
        this.props.toggleLocationCard(loc, true);
      }
    )
    return null
  }

  // Remove custom pins/polygons from map
  private removeEnts = () => {
    if (this.state.locationPin) {
      mapStore.map!.entities.remove(this.state.locationPin!)
    }

    if (this.state.locationPoly) {
      mapStore.map!.entities.remove(this.state.locationPoly!)
    }
  }

  // Search locations by name (manually clicking search)
  private searchLocation = () => {
    const loc = geoCode(this.searchRef.current.value);
    if (loc) {
      addPin(loc);
      this.props.toggleLocationCard(loc, true);
    }
  }
}

export default view(LocationSearchInput)
