import React, { Fragment, useState } from 'react'
import { view } from 'react-easy-state'
import { Button } from 'reactstrap'
import { UPDATE_FREQUENCY, useNowcastApi } from '/common/api'
import { GeoLocation, RadarCast } from '/common/types'
import { useInterval } from '/common/utils'
import {
  Accordion,
  AccordionHeader,
  AccordionItem
} from '/components/Accordion'
import ApiLoadingFragment from '/components/ApiLoadingFragment'
import LastUpdated from '/components/LastUpdated'
import NowcastTable from '/components/NowcastTable'
import RadarViewer from '/components/RadarViewer'

interface Props {
  location: GeoLocation
}

function NowcastTableContainer({
  radar,
  idx
}: {
  radar: RadarCast
  idx: number
}) {
  // open first forecast by default
  const first = idx === 0
  const distance = radar.distance.toFixed(1)

  const Title = () => (
    <AccordionHeader
      title={radar.site_name}
      subtitle={<Fragment>{distance}km away</Fragment>}
    />
  )

  const [viewOpen, setViewOpen] = useState(false)
  const toggle = () => setViewOpen(!viewOpen)

  const now = new Date()

  // filter out old predictions
  radar.values = radar.values.filter(prediction => {
    const time = new Date(prediction.time)
    const diff = Math.abs(time.getTime() - now.getTime())

    // future cast so show regardless
    if (time > now) return true

    // else check if within 10 minutes
    const mins = Math.floor(diff / 1000 / 60) % 60
    return mins <= 10
  })

  return (
    <Fragment>
      <AccordionItem title={<Title />} startOpen={first}>
        <Button className="mb-3" onClick={toggle}>
          View predicted radar images
        </Button>
        <LastUpdated date={radar.modelled} message="Models last generated" />
        {radar.values.length > 0 ? (
          <NowcastTable predictions={radar.values} />
        ) : (
          <p>No recent nowcasts currently available for this location.</p>
        )}
        <RadarViewer radar={radar} open={viewOpen} toggle={toggle} />
      </AccordionItem>
    </Fragment>
  )
}

function Nowcasts({ location }: Props) {
  const [lastUpdate, setLastUpdate] = useState(new Date())

  // update UI periodically
  useInterval(() => {
    setLastUpdate(new Date())
  }, 1000 * UPDATE_FREQUENCY)

  const { data, loading, errors } = useNowcastApi(location, lastUpdate)

  // all responses return an array of NowcastResponse, even if only asking for one location
  // so grab first element of NowcastResponse[] and use that.
  const validResponse = !loading && !errors && data.length > 0
  const validNowcast = validResponse && 'radar_casts' in data[0]

  let radarCasts: RadarCast[] = []
  if (validNowcast) radarCasts = data[0].radar_casts

  const disclaimer = (
    <Fragment>
      <p className="text-muted">
        Probability is the percentage of nowcast predictions that have rainfall
        above a set threshold.
      </p>
      <p className="text-muted">
        These values are based on predictions made by using the{' '}
        <abbr title="Short Term Ensemble Prediction System">STEPS</abbr> method
        on radar images obtained from the Bureau of Meteorology. The specific
        value is taken by reading the pixel that corresponds to the provided
        location.
      </p>
    </Fragment>
  )

  return (
    <Fragment>
      <LastUpdated date={lastUpdate} message="Last refreshed" />
      <hr className="mt-0" />
      {validNowcast ? (
        radarCasts.length > 0 ? (
          <Accordion>
            {radarCasts
              .sort((a, b) => a.distance - b.distance)
              .map((radar: RadarCast, idx) => (
                <NowcastTableContainer radar={radar} idx={idx} key={idx} />
              ))}
          </Accordion>
        ) : (
          <div className="mb-3">
            No nowcasts currently available for this location.
          </div>
        )
      ) : (
        <ApiLoadingFragment loading={loading} errors={errors}>
          <p>
            An error occured while loading nowcast data. Please try again later.
          </p>
        </ApiLoadingFragment>
      )}
      <hr className="mt-0" />
      {disclaimer}
    </Fragment>
  )
}

export default view(Nowcasts)
