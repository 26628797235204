import React from 'react'
import { view } from 'react-easy-state'
import ReactTimeAgo from 'react-time-ago'

export const LastUpdated: React.FC<{
  date: string | Date
  message?: string
}> = ({ date, message = 'Last updated' }) => {
  const d = new Date(date)
  return (
    <p className="text-muted">
      {message} <ReactTimeAgo date={d} />.
      {/* {d.toLocaleDateString('en-AU', {
        minute: 'numeric',
        hour: 'numeric',
        weekday: 'long',
        day: 'numeric',
        month: 'long'
      })} */}
    </p>
  )
}

export default view(LastUpdated)
