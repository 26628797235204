import React from 'react'
import { view } from 'react-easy-state'
import { Button } from 'reactstrap'
import { mapStore } from '/common/stores'
import { viewDefaults } from '/common/utils'

interface Props {
  className?: string
  onClick?: () => void
}

export const ResetMapButton = view(({ className = '' }: Props) => {
  const onClick = () => {
    mapStore.map.setViewCenter(viewDefaults.center[0], viewDefaults.center[1], viewDefaults.zoom);
  }

  return (
    <Button className={className} onClick={onClick}>
      Re-centre Map
    </Button>
  )
})

export const BackToMapButton = view(
  ({ className = '', onClick = () => null }: Props) => {
    return (
      <Button className={className} onClick={onClick}>
        Return to Map
      </Button>
    )
  }
)
