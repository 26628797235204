import { useCallback, useEffect, useRef, useState } from 'react'
import createPersistedState from 'use-persisted-state'
import { mapStore } from '/common/stores'
import { DataLocation, GeoLocation, Radar } from '/common/types'

import $ from 'jquery'
import { IoMdLocate } from 'react-icons/io';

export const viewDefaults = {
  center: [133.2710261654393, -28.385860297641376],
  zoom: 15000000
}
// Load the given script asyncronously and trigger a callback upon load
export function loadScript(
  src: string,
  callback?: () => void,
  id: string = 'script'
) {
  const existingScript = document.getElementById(id)

  if (!existingScript) {
    const ref = window.document.getElementsByTagName('script')[0]
    const script = window.document.createElement('script')
    script.src = src
    script.async = true
    script.id = id
    script.onload = () => {
      if (callback) callback()
    }
    ref.parentNode!.insertBefore(script, ref)
  }

  if (existingScript && callback) callback()
}

// Load the Bing Maps API script
export function loadBingMaps(callback: () => void) {
  window.loadedMap = callback
  loadScript(
    'https://www.bing.com/api/maps/mapcontrol?key=' + config.bingKey + '&callback=loadedMap',
    () => null,
    'bingMapScript'
  )
}

export function addPin(loc: GeoLocation): any {
  var layer = mapStore.map.getTree().findByTitle("Point");
  layer.reload();
  var pin = mapStore.map.createPoint(loc.longitude, loc.latitude, layer);
  layer.redraw();
  mapStore.map.setViewCenter(loc.longitude, loc.latitude, 5000);
  return pin;
}

// Check a given latitude & longitude pair is within range
export function validateLocation({ latitude, longitude }: GeoLocation) {
  const validLat = !isNaN(latitude) && latitude <= 90.0 && latitude >= -90.0
  const validLon =
    !isNaN(longitude) && longitude <= 180.0 && longitude >= -180.0
  return validLat && validLon
}

// Parse string latitude & longitude into floats
export function makeGeoLocation({
  latitude,
  longitude
}: {
    [key: string]: string
  }): GeoLocation | null {
  // check if strings are valid lat/long floats before converting
  const lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
  const long = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/
  const validLat = lat.test(latitude)
  const validLong = long.test(longitude)

  if (validLat && validLong) {
    const location: GeoLocation = {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude)
    }

    if (validateLocation(location)) return location
  }

  return null
}

export const useSavedLocationsState = createPersistedState('locations')
export const useSavedNotificationsState = createPersistedState('notifications')

export function latLongCardinal({ latitude, longitude }: GeoLocation) {
  return `${latitude.toFixed(2)}º${
    latitude < 0 ? 'S' : 'N'
    } ${longitude.toFixed(2)}º${longitude < 0 ? 'W' : 'E'}`
}

export function grantNotificationPermission() {
  if (!('Notification' in window)) {
    return
  }

  if (Notification.permission === 'granted') {
    return
  }

  if (Notification.permission !== 'denied') {
    const promise = Notification.requestPermission().then(result => {
      if (result === 'granted') {
        const notify = new Notification(
          'Awesome! You will start receiving notifications shortly.'
        )
      }
    })
  }
}

export function showNotification(title: string, message: string) {
  const notify = new Notification(title, { body: message })
}

type IntervalFunction = () => unknown | void

/**
 * React hook wrapping around setInterval.
 *
 * Written by Dan Abramov,
 * from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback function to run on each delay
 * @param delay interval for function
 */
export function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = useRef<IntervalFunction | null>(null)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  })

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current()
      }
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export function useForceUpdate(): () => void {
  // Returning a new object reference guarantees that a before-and-after
  //   equivalence check will always be false, resulting in a re-render, even
  //   when multiple calls to forceUpdate are batched.

  const [, dispatch] = useState<{}>(Object.create(null))

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback((): void => {
    dispatch(Object.create(null))
  }, [dispatch])

  return memoizedDispatch
}

const config = {
  "__comment": "The default map ",
  "resourcesPath": "https://api.stage.mapworks.io/resources",
  "mapworksPath": "https://app.stage.mapworks.io",
  "mapPath": "https://api.stage.mapworks.io/maps/latest",
  "mapworksSessionPath": "https://app.stage.mapworks.io/users/plugins/mapworks/getLoginStatus.php",
  "mapworksGetAccountsPath": "https://app.stage.mapworks.io/users/plugins/mapworks/getAccountsByIds.php",
  "mapworksUpdateAccountPath": "https://app.stage.mapworks.io/users/plugins/mapworks/updateAccount.php",
  "defaultMap": "AW8RXXXmAAA2ac12AAAA",
  "studioPath": "https://api.stage.mapworks.io/studio/latest",
  "bingKey": "AiRX6VGPHOMmLeG2b3QWw0Ii_2u1qQKcTrEdaANgrjabX_1HWJ1brFTqia_4mNBz",
  "mapInfo": {
    "shareUrlTemplate": "<%= studioPath %>/?map=<%= mapId %>&x=<%= x %>&y=<%= y %>&scale=<%= scale %>"
  },
  "mapworksPeliasPath": "https://api.stage.mapworks.io/pelias/",
  "peliasKey": "CQkCDAkJAg4HCAQFBA4AAw",
  attributionInfo: {
    // unique key for the attribution
    OSM: {
      // Title will be shown in the attribution list
      title: 'OpenStreetMap contributors',
      // Title will be shown in a printed map
      printTitle: 'openstreetmap.org',
      // [optional] link when clicking on the attribution
      url: 'https://www.openstreetmap.org/copyright',
      // [optional] friendly description of the attribution.
      description: 'Details on OSM attribution'
    },
    SLIP: {
      // Title will be shown in the attribution list
      title: 'SLIP',
      // Title will be shown in a printed map
      printTitle: 'data.wa.gov.au',
      // [optional] link when clicking on the attribution
      url: 'https://data.wa.gov.au/',
      // [optional] friendly description of the attribution.
      description: 'Details on SLIP Attribution'
    },
    TT: {
      // Title will be shown in the attribution list
      title: 'TomTom',
      // Title will be shown in a printed map
      printTitle: 'tomtom.com',
      // [optional] link when clicking on the attribution
      url: 'https://www.tomtom.com/en_gb/legal/guidelines-trademarks-copyrights/',
      // [optional] friendly description of the attribution.
      description: 'Details on TomTom Attribution'
    }
  }
};

export function createRadarPoint(radars: Radar[]) {
  var layer = mapStore.map.getTree().findByTitle("Radar");
  layer.reload();
  radars.forEach((radar: Radar) => {
    var point = mapStore.map.createPoint(radar.longitude, radar.latitude, layer);
    point.setFields({ "Name": radar.name });
  });
  layer.redraw();
};

export function loadMapworks(callback?: () => void) {
  if (!mapStore.map) {
    var mapId = Studio._.urlParam("map");
    var mode = Studio._.urlParam("mode");
    var apikey = Studio._.urlParam("apikey");
    var apiversion = Studio._.urlParam("apiversion");
    var mapState = Studio._.urlParam('mapState');
    var newMap = Studio._.urlParam('newMap') === 'true';
    var initApp = Studio._.urlParam('initApp');
    initApp = (initApp || 'true') === 'true';
    var tokenType = Studio._.urlParam('tokenType');
    var token = Studio._.urlParam('token');
    var orgId = Studio._.urlParam('orgId');

    window.map = new Studio.init(
      "#embed1",
      {
        apiKey: apikey || config.apiKey,
        apiVersion: apiversion || config.apiVersion,
        tokenType: tokenType,
        token: token,
        orgId: orgId,
        map: mapId || window.slipMap || config.defaultMap,
        mode: mode || Studio.core.Map.Mode.MAP,
        mapworksPath: config.mapworksPath,
        resourcesPath: config.resourcesPath,
        mapPath: config.mapPath,
        studioPath: config.studioPath,
        mapworksSessionPath: config.mapworksSessionPath,
        mapworksGetAccountsPath: config.mapworksGetAccountsPath,
        mapworksUpdateAccountPath: config.mapworksUpdateAccountPath,
        //authentication: config.authentication,
        mapState: mapState,
        newMap: newMap,
        navigationControl: false,
        timeControl: true,
        scaleControl: true,
        toolbarControl: false,
        zoomControl: false,
        pollPublicationStatus: config.pollPublicationStatus,
        //attributionInfo: config.attribution
      },
      config
    ).on('ready', function () {
      mapStore.map = map;
      mapStore.loaded = true;
      console.log("READY");

      // Add Radar Layer
      var radarLayer = new Studio.core.entity.TreeVectorLayerEntity({
        attributes: {
          maxScale: map.getMaxScale(),
          minScale: map.getMinScale()
        },
        title: "Radar",
        visible: true,
        active: true,
        labelled: true,
        fields: { "name": { name: "name", title: "Name" } },
        layerFields: [{ "name": { name: "name", title: "Name" } }],
        styles: {
          "#": {
            "active": {
              "pointFill": "#FFFF66",
              "pointIconFill": "#FFFF66"
            },
            "default": {
              "lineDash": [
                0
              ],
              "labelFill": "#000",
              "labelFont": "Sans-Serif",
              "labelSize": [
                12
              ],
              "pointFill": "#f00",
              "labelForce": false,
              "pointWidth": 8,
              "polygonFill": "#f00",
              "pointOpacity": 1,
              "hoverTemplate": "|name|",
              "pointIconFill": "#f00",
              "pointLineFill": "#2e2e2e",
              "pointLineWidth": 1,
              "polygonLineFill": "#2e2e2e",
              "pointLineOpacity": 1
            },
            "selected": {
              "pointFill": "#FFBF1A",
              "pointIconFill": "#FFBF1A"
            },
            "order": 1
          }
        },
        source: {
          adhoc: {
            "features": {},
            "fields": [{ name: "name", send: true, type: 311, id: "name" }]
          }
        }
      }, { map: map });
      map.getTree().add(radarLayer);

      // Add Point Layer
      var pointLayer = new Studio.core.entity.TreeVectorLayerEntity({
        attributes: {
          maxScale: map.getMaxScale(),
          minScale: map.getMinScale()
        },
        title: "Point",
        visible: true,
        active: false,
        labelled: false,
        fields: { "name": { name: "name", title: "Name" } },
        layerFields: [{ "name": { name: "name", title: "Name" } }],
        styles: {
          "#": {
            "default": {
              "pointFill": "#ffbf1a",
              "lineFill": "#00f",
              "lineWidth": [
                1
              ],
              "polygonFill": "#ffbf1a",
              "polygonLineFill": "#ffbf1a",
              "polygonLineWidth": 21,
              "polygonOpacity": 0.3,
              "pointWidth": 5,
              "labelFont": "Sans-Serif",
              "labelSize": [
                12
              ],
              "hoverTemplate": "|name|",
              "labelPriority": 6,
              "labelForce": true,
              "pointLineFill": "#ffbf1a",
              "pointIconFill": "#ffbf1a",
              "lineDash": [
                0
              ],
              "pointLineWidth": 21
            },
            "active": {
              "pointFill": "#ffff66",
              "lineFill": "#ffff66",
              "polygonFill": "#ffff66",
              "polygonOpacity": 0.3,
              "labelForce": false,
              "lineDash": [
                0
              ],
              "pointLineFill": "#ffff66",
              "polygonLineFill": "#ffff66",
              "pointIconFill": "#ffff66"
            },
            "selected": {
              "pointFill": "yellow",
              "lineFill": "yellow",
              "polygonFill": "yellow",
              "polygonOpacity": 0.3
            },
            "order": 1
          }
        },
        source: {
          adhoc: {
            "features": {},
            "fields": [{ name: "name", send: true, type: 311, id: "name" }]
          }
        }
      }, { map: map });
      map.getTree().add(pointLayer);
      map.removeControl('search');

      map.setViewCenter(viewDefaults.center[0], viewDefaults.center[1], viewDefaults.zoom);

      if (callback) callback();
    }).on('unauthorized', function () {
      Studio.app.component.dialogue.Dialogue.alert(
        {
          title: 'API Key Error',
          message: [
            'The API key is invalid.',
            'Please provide a new API key:',
            '<textarea id="apikey"></textarea>'
          ]
            .join(' ')
        },
        function () {
          var apikey = document.getElementById('apikey').value.trim();
          if (apikey) {
            map.setApiKey(apikey);
          }
        }
      );
    }).on('fetch:failed', function (response) {
      var message;
      switch (response.status) {
        case 403:
          message = 'Access to this map is denied.'
          if (config.mapworksPath) {
            message += [
              ' Please click <a href="',
              config.mapworksPath,
              '#/map?id=',
              encodeURIComponent(mapId),
              '">here</a> to configure the map.'
            ]
              .join('')
          }
          break;
        case 404:
          message = 'The requested map is not found.';
          break
        default:
          message = response.responseJSON.message || [
            'Server response:',
            response.status,
            response.statusText
          ].join(' ');
          break;
      }
      Studio.app.component.dialogue.Dialogue.alert({
        title: 'Error loading map',
        message: message
      });
    });
  }
}

export function reverseGeocode(loc: GeoLocation): String | null {
  var name = null;
  $.ajax({
    url: "https://dev.virtualearth.net/REST/v1/Locations/"
      + loc.latitude + "," + loc.longitude
      + "?key=" + config.bingKey,
    dataType: 'json',
    type: "GET",
    async: false,
    crossDomain: true,
    success: function (data: any) {
      if (data && data.resourceSets && data.resourceSets.length > 0) {
        name = data.resourceSets[0].resources[0].name;
      }
    },
    error: function (res: any) {
    }
  });
  return name;
}

export function geoCode(query: string): DataLocation | null {
  var dLoc: DataLocation = null;
  $.ajax({
    url: "https://dev.virtualearth.net/REST/v1/Locations/"
      + "q=" + encodeURI(query)
      + "?key=" + config.bingKey
      + "&incl=queryParse,ciso2",
    dataType: 'json',
    type: "GET",
    async: false,
    crossDomain: true,
    success: function (data: any) {
      if (data && data.resourceSets && data.resourceSets.length > 0) {
        data.resourceSets[0].resources.forEach((d) => {
          if (["AU", "US"].indexOf(d.address.countryRegionIso2) >= 0) {
            dLoc = {
              name: d.name,
              latitude: d.point.coordinates[0],
              longitude: d.point.coordinates[1]
            };
          }

        });
      }
    },
    error: function (res: any) {
    }
  });
  return dLoc;
}
