import React, { Fragment, useState } from 'react'
import ReactTimeAgo from 'react-time-ago'
import { Toast, ToastBody } from 'reactstrap'
import { useStatsApi } from '/common/api'
import { DataLocation } from '/common/types'
import { useInterval } from '/common/utils'
import '/components/Stats.css'

function Stats({
  toggleLocationCard
}: {
  toggleLocationCard: (location?: DataLocation, open?: boolean) => void
}) {
  const [lastUpdate, setLastUpdate] = useState(new Date())

  // update stats every minute
  useInterval(() => {
    setLastUpdate(new Date())
  }, 1000 * 1 * 60)

  const { data, loading, errors } = useStatsApi(lastUpdate)

  if (errors) return null

  const loadingText = <span className="text-muted">Loading...</span>

  const navigate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    toggleLocationCard(
      {
        latitude: data.latest_modelled_site_latitude,
        longitude: data.latest_modelled_site_longitude,
        name: data.latest_modelled_site
      },
      true
    )
  }

  if (data || loading) {
    return (
      <Toast id="stats-overlay">
        <div className="toast-header">
          <strong className="flex-grow-1">Statistics</strong>
          <small>
            Updated <ReactTimeAgo date={lastUpdate} />
          </small>
        </div>
        <ToastBody className="px-3 py-2">
          {loading ? (
            <dl className="row my-0">
              <dt className="col-sm-12">Average modelling delay</dt>
              <dd className="col-sm mb-1">{loadingText}</dd>
              <dt className="col-sm-12">Latest modelled site</dt>
              <dd className="col-sm mb-1">{loadingText}</dd>
              <dt className="col-sm-12">Available Radars</dt>
              <dd className="col-sm mb-1">{loadingText}</dd>
              <dt className="col-sm-12">Images in modelling queue</dt>
              <dd className="col-sm mb-0">{loadingText}</dd>
            </dl>
          ) : (
            <dl className="row my-0">
              {data.avg_modeltime && (
                <Fragment>
                  <dt className="col-sm-12">Average modelling delay</dt>
                  <dd className="col-sm mb-1">
                    ~{data.avg_modeltime.toFixed(0)} seconds
                  </dd>
                </Fragment>
              )}
              {data.latest_modelled_site && (
                <Fragment>
                  <dt className="col-sm-12">Latest modelled site</dt>
                  <dd className="col-sm mb-1">
                    <a
                      href={`/?loc=${data.latest_modelled_site_longitude},${data.latest_modelled_site_latitude}`}
                      onClick={navigate}
                    >
                      {data.latest_modelled_site}
                    </a>
                  </dd>
                </Fragment>
              )}
              <dt className="col-sm-12">Available Radars</dt>
              <dd className="col-sm mb-1">{data.num_radars}</dd>
              <dt className="col-sm-12">Images in modelling queue</dt>
              <dd className="col-sm mb-0">{data.unmodelled_images}</dd>
            </dl>
          )}
        </ToastBody>
      </Toast>
    )
  }

  return null
}

export default Stats
