import React from 'react'
import { view } from 'react-easy-state'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { Forecast } from '/common/types'

interface Props {
  forecast: Forecast
}

// Single forecast with a weather icon, and maximum and minimum temperatures
function ForecastInfo({ forecast }: Props) {
  const iconRef = React.createRef<HTMLSpanElement>()
  const start = new Date(forecast.date_of)

  const forecastCodeNames = [
    '',
    'Sunny',
    'Clear',
    'Mostly sunny',
    'Cloudy',
    '',
    'Hazy',
    '',
    'Light rain',
    'Windy',
    'Fog',
    'Shower',
    'Rain',
    'Dusty',
    'Frost',
    'Snow',
    'Storm',
    'Light shower',
    'Heavy shower',
    'Cyclone'
  ]

  const forecastCodeEmojis = [
    '', // unassigned
    '\u2600\uFE0F', // Sunny
    '\uD83C\uDF19', // Clear
    '\u26C5', // Mostly sunny
    '\u2601\uFE0F', // Cloudy
    '', // unassigned
    '\uD83C\uDF01', // Hazy
    '', // unassigned
    '\uD83C\uDF27\uFE0F', // Light rain
    '\uD83C\uDF2C\uFE0F', // Windy
    '\uD83C\uDF01', // Fog
    '\uD83C\uDF26\uFE0F', // Shower
    '\uD83C\uDF27\uFE0F', // Rain
    '\uD83D\uDE37', // Dusty
    '\u2744\uFE0F', // Frost
    '\u2603\uFE0F', // Snow
    '\uD83C\uDF29\uFE0F', // Storm
    '\uD83C\uDF27\uFE0F', // Light shower
    '\uD83C\uDF27\uFE0F', // Heavy shower
    '\uD83C\uDF00' // Cyclone
  ]

  return (
    <div className="text-center">
      <Row className="mx-0 align-items-center">
        {forecast.icon && typeof forecast.icon === "number" && (
          <Col md={1} lg={6} xl={1} className="h1 mb-2 my-md-auto p-0 order-2 order-lg-2 order-xl-1">
            <span ref={iconRef} style={{ cursor: 'help' }}>
              {forecastCodeEmojis[forecast.icon || 0]}
            </span>
            <UncontrolledTooltip target={(iconRef as unknown) as HTMLElement}>
              {forecastCodeNames[forecast.icon || 0]}
            </UncontrolledTooltip>
          </Col>
        )}
        {forecast.icon && typeof forecast.icon === "string" && (
          <Col className="h1 mb-2 my-md-auto p-0 order-2 order-lg-2 order-xl-1">
            <span className="text-primary h5 my-0 ml-3">
              <img src={forecast.icon} alt="" />
            </span>
          </Col>
        )}
        <Col className="h5 mb-2 my-md-auto order-1 order-lg-1 order-xl-2">
          <span>
            {forecast.name ?
              forecast.name + ', ' + start.toLocaleDateString('en-AU', { month: 'short', day: 'numeric' }) :
              start.toLocaleDateString('en-AU', {
                weekday: 'short',
                month: 'short',
                day: 'numeric'
              })}
          </span>
        </Col>
        {forecast.forecast_maximum != null && (
          <Col className="text-center align-items-center order-3" style={{ whiteSpace: 'nowrap' }}>
            Max
            <span className="text-danger h5 my-0 ml-3">
              {forecast.forecast_maximum}
            </span>
            <span className="text-danger ml-1">°C</span>
          </Col>
        )}
        {forecast.forecast_minimum != null && (
          <Col className="text-center align-items-center order-4" style={{ whiteSpace: 'nowrap' }}>
            Min
            <span className="text-primary h5 my-0 ml-3">
              {forecast.forecast_minimum}
            </span>
            <span className="text-primary ml-1">°C</span>
          </Col>
        )}
        <div className="w-100 order-5" />
        {forecast.precis && (
          <Col className="order-6 mt-2">{forecast.precis}</Col>
        )}
        {forecast.precipitation_probability && (
          <Col className="order-6 mt-2">
            Chance of any rain: {forecast.precipitation_probability}
          </Col>
        )}
      </Row>
    </div >
  )
}

export default view(ForecastInfo)
