import React, { Fragment } from 'react'
import QueryBuilder, {
  CombinatorSelectorCustomControlProps,
  Rule,
  RuleGroup,
  ValueEditorCustomControlProps
} from 'react-querybuilder'
import { ValueEditor } from 'react-querybuilder/dist/index.js'
import '/components/PredicateBuilder.css'

export interface OptionalIdRule extends Omit<Rule, 'id'> {
  id?: string
}

export interface OptionalIdRuleGroup extends Omit<RuleGroup, 'id' | 'rules'> {
  id?: string
  rules?: Array<OptionalIdRule | OptionalIdRuleGroup>
}

const valueEditor: React.FunctionComponent<
  ValueEditorCustomControlProps
> = props => {
  if (props.operator === 'null' || props.operator === 'notNull') {
    return null
  }

  switch (props.type) {
    case 'select':
    case 'checkbox':
    case 'radio':
      return <ValueEditor {...props} />

    default:
      let minMax = {}
      if (props.fieldData && props.fieldData.unit === '%') {
        // percent based, set min and max
        minMax = { min: 0, max: 100 }
      }

      return (
        <Fragment>
          <input
            type={props.inputType || 'text'}
            value={props.value}
            title={props.title}
            className={props.className}
            onChange={e =>
              props.handleOnChange && props.handleOnChange(e.target.value)
            }
            {...minMax}
          />
          {props.fieldData && props.fieldData.unit && (
            <span>{props.fieldData.unit}</span>
          )}
        </Fragment>
      )
  }
}

// Dropdown to select condition for group matches
const combinatorSelector: React.FunctionComponent<
  CombinatorSelectorCustomControlProps
> = ({ className, handleOnChange, options, title, value }) => {
  const onChange = (e: React.FormEvent<HTMLSelectElement>) =>
    handleOnChange && handleOnChange(e.currentTarget.value)

  return (
    <div className="flex-grow-1">
      Matches
      <select
        className={className}
        value={value}
        title={title}
        onChange={onChange}
      >
        {options.map(option => {
          const key = `key-${option.name}`
          return (
            <option key={key} value={option.name}>
              {option.label}
            </option>
          )
        })}
      </select>
      of the following rules:
    </div>
  )
}

const isTrue = (val: any) => val === true

// group match conditions
export const combinators = [
  {
    name: 'AND',
    label: 'All',
    fn: (...args: RuleMatch[]) => args.every(val => isTrue(val.result))
  },
  {
    name: 'OR',
    label: 'Any',
    fn: (...args: RuleMatch[]) => args.some(val => isTrue(val.result))
  }
]

// comparison operators for strings
export const stringOperators = [
  {
    name: 'in',
    label: 'contains',
    fn: (a: string, b: string) => a.includes(b)
  },
  {
    name: 'notIn',
    label: 'does not contain',
    fn: (a: string, b: string) => !a.includes(b)
  }
]

export const equalityOperators = [
  // tslint:disable-next-line: triple-equals
  { name: '=', label: 'is', fn: (a: any, b: any) => a == b },
  {
    name: '!=',
    label: 'is not',
    // tslint:disable-next-line: triple-equals
    fn: (a: any, b: any) => a != b
  }
]

// comparison operators for numbers (and other types)
export const numberOperators = [
  ...equalityOperators,
  {
    name: '<',
    label: 'is less than',
    fn: (a: any, b: any) => a < b
  },
  {
    name: '>',
    label: 'is greater than',
    fn: (a: any, b: any) => a > b
  }
]

// comparison operators for time (and other types)
export const timeOperators = [
  ...equalityOperators,
  {
    name: '<',
    label: 'is before',
    fn: (a: any, b: any) => a < b
  },
  {
    name: '>',
    label: 'is after',
    fn: (a: any, b: any) => a > b
  }
]

// comparison operators for rules
export const operators = [...stringOperators, ...numberOperators]

// fields that can be used as rule inputs
export const fields = [
  {
    name: 'intensity',
    label: 'Predicted Rainfall',
    type: 'number',
    unit: 'mm/h'
  },
  { name: 'time', label: 'Time', type: 'time' },
  {
    name: 'probability',
    label: 'Probability of Rainfall',
    type: 'number',
    unit: '%'
  }
]

// custom input types for specific fields
const getInputType = (field: string, operator: string): string => {
  const f = fields.find(val => val.name === field)
  if (f && 'type' in f) return f.type
  return 'text'
}

// use custom operators for certain fields to
// allow type comparison i.e. numbers
const getOperators = (field: string) => {
  switch (field) {
    case 'intensity':
    case 'probability':
      return numberOperators

    case 'time':
      return timeOperators

    default:
      return operators
  }
}

export interface RuleMatch {
  rule: Rule | RuleMatch[]
  result: boolean
  asString: string[]
}

/**
 * Resolves a rule group to a boolean value.
 * Indicates this rule group tests positive against the given data.
 *
 * @param ruleGroup A group of rules to resolve
 * @param data Data to be used for resolving
 */
export function parseRuleGroup(ruleGroup: RuleGroup, data: any): RuleMatch {
  // default combinator matches no rows
  let combinatorFn: (...args: any) => boolean = (...args) => false

  // select combinator to use for group rules
  const combinator = combinators.find(val => val.name === ruleGroup.combinator)
  if (combinator) {
    combinatorFn = combinator.fn
  }

  const ruleResults: RuleMatch[] = ruleGroup.rules.map(rule => {
    if ('rules' in rule) {
      // is a rulegroup
      return parseRuleGroup(rule, data)
    } else {
      // is a rule
      const parsed = parsePredicate(
        rule.field,
        data[rule.field],
        rule.operator,
        rule.value
      )

      return {
        rule,
        result: parsed,
        asString: [describeRule(rule)]
      }
    }
  })

  // get true predicates as strings
  const strings = ([] as string[]).concat(
    ...ruleResults
      .filter(rule => rule.result === true)
      .map(rule => rule.asString)
  )

  return ruleResults.length > 0
    ? {
        rule: ruleResults,
        // combine test result of each rule into a boolean using the selected combinator
        result: combinatorFn(...ruleResults),
        // each test result as a string
        asString: strings
      }
    : {
        rule: ruleResults,
        // fail empty groups
        result: false,
        asString: strings
      }
}

export function describeRule(rule: Rule) {
  const op = operators.find(val => val.name === rule.operator)
  const f = fields.find(val => val.name === rule.field)
  if (op && f) {
    return `${f.label} ${op.label} ${rule.value}${f.unit ? ' ' + f.unit : ''}.`
  }

  return ''
}

/**
 * Resolve a predicate given an input parameter and a target value.
 *
 * @param field Field this predicate operates using
 * @param data Input data to compare with value
 * @param operator Operator used to compare data and value
 * @param value Rule value
 */
export function parsePredicate(
  field: string,
  data: any,
  operator: string,
  value: any
): boolean {
  // default compare matches no values
  let comparerFn = (a: any, b: any) => false

  // get operator function
  const op = operators.find(val => val.name === operator)
  if (op) {
    comparerFn = op.fn

    // convert from string if necessary
    switch (getInputType(field, operator)) {
      case 'number':
        if (field === 'probability') {
          // convert percentage to 0-1 from 0-100
          value = parseInt(value, 10) / 100
        } else {
          value = parseInt(value, 10)
        }
        break
    }
  }

  // compare the input data against target value
  return comparerFn(data, value)
}

export function PredicateBuilder({
  query,
  onQueryChange = (q: RuleGroup) => {}
}: {
  query?: RuleGroup
  onQueryChange?: (q: RuleGroup) => void
}) {
  return (
    <QueryBuilder
      fields={fields}
      onQueryChange={onQueryChange}
      query={query}
      combinators={combinators}
      operators={operators}
      getInputType={getInputType}
      getOperators={getOperators}
      controlElements={{ combinatorSelector, valueEditor }}
      controlClassnames={{
        ruleGroup: 'form-inline w-100 pt-2 border-bottom border-top',
        combinators: 'form-control custom-select custom-select-sm mx-2',
        addRule: 'btn btn-sm btn-primary ml-2',
        addGroup: 'btn btn-sm btn-primary ml-2',
        removeGroup: 'btn btn-sm btn-danger ml-2',

        rule: 'w-100 border border-left-0 border-right-0',
        fields: 'form-control custom-select custom-select-sm mr-2',
        operators: 'form-control custom-select custom-select-sm mr-2',
        value: 'form-control form-control-sm mr-2',
        removeRule: 'btn btn-sm btn-danger ml-2 float-right'
      }}
    />
  )
}
