/// <reference path="../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />
/// <reference path="modules.d.ts" />

import 'bootstrap/dist/css/bootstrap.css'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import React, { Fragment } from 'react'
import 'react-dates/initialize'
import ReactDOM from 'react-dom'
import { QueryParamProvider } from 'use-query-params'
import history from './history'
import App from '/App'
import NotificationProvider from '/components/NotificationProvider'

TimeAgo.locale(en)

const mountNode = document.getElementById('app')
ReactDOM.render(
  <Fragment>
    <NotificationProvider />
    <QueryParamProvider history={history}>
      <App />
    </QueryParamProvider>
  </Fragment>,
  mountNode
)

if (module.hot) {
  module.hot.accept()
}
