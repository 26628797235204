import React from 'react'
import { view } from 'react-easy-state'
import { mapStore } from '/common/stores'
import { loadBingMaps, loadMapworks } from '/common/utils'
import LoadingElement from '/components/LoadingElement'

interface Props {
  callback?: () => void
}

export class MicrosoftLoader extends React.Component<Props> {
  constructor(props: any) {
    super(props)
  }

  public doCallback() {
    if (mapStore.loaded) {
      if (this.props.callback) this.props.callback()
    }
  }

  public componentDidMount() {
    if (mapStore.loaded) {
      return this.doCallback()
    }

    // load bing script, then load needed modules
    loadBingMaps(() => {
      Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {
        callback: () => {
          mapStore.loaded = true
          this.doCallback()
        }
      });
    })
  }

  public componentDidUpdate() {
    this.doCallback()
  }

  public render() {
    if (mapStore.loaded) {
      return this.props.children
    }

    return <LoadingElement />
  }
}

export default view(MicrosoftLoader)
